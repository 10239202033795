import React, { useEffect, useState } from "react";
import MainForm from '../components/MainForm/MainForm'
import { useParams } from 'react-router-dom';
import { connect } from "react-redux";
import dateFormat from "dateformat";
import { setCreateReservation, } from '../store/actions/reservationAction'
import { setAllProperty, setAllAvailRoomByDate, resetAllAvailRoom } from '../store/actions/propertyAction'
import { setReservationById, setUpdateDetailReservation, setUpdateStatusReservation } from '../store/actions/reservationAction'
import { setAllHotel, } from '../store/actions/hotelAction'
import { checkResponseMessage } from '../helper/helper'

const ReservationDetailUpdatePage = ({ dispatch, dataReservation, dataProperty, dataHotel }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [id, setId] = useState("");
  const { reservationId } = useParams()

  const [name, setName] = useState("");
  const [KTP, setKTP] = useState("");
  const [address, setAddress] = useState("");
  const [pagination, setPagination] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [bookingSource, setBookingSource] = useState({id:0, name: "Direct Booking"});
  const [price, setPrice] = useState("");
  const [bookTime, setBookTime] = useState("");
  const [guests, setGuests] = useState("");
  const [roomType, setRoomType] = useState("");
  const [notes, setNotes] = useState("");
  const [country, setCountry] = useState("");
  const [paymentMethod, setPaymentMethod] = useState({id: 0, name: "Cash"});
  
  const [allAvailHotel, setAllAvailHotel] = useState([]);
  const [allAvailBookingSource, setAllAvailBookingSource] = useState([
    {id: 0, name:"Direct Booking"},
    {id: 1, name:"Walk In Guest"},
    {id: 2, name:"OTA Traveloka"},
    {id: 3, name:"OTA Tiket.com"},
    {id: 4, name:"OTA Agoda"},
    {id: 5, name:"OTA Booking.com"},
    {id: 6, name:"OTA C-TRIP"},
    {id: 7, name:"OTA Expedia"},
    {id: 8, name:"OTA KlikandBook"},
    {id: 9, name:"OTA MG Holiday"},
    {id: 10, name:"OTA ZUZU"},
    {id: 11, name:"OTA RedDoorz"},
  ]);
  const [allAvailPaymentMethod, setAllAvailPaymentMethod] = useState([
    {id: 1, name:"Cash"},
    {id: 2, name:"Debit Card"},
    {id: 3, name:"Credit Card"},
    {id: 4, name:"Qris"},
    {id: 5, name:"City Ledger to NamRoom.id"},
    {id: 6, name:"City Ledger to Zuzu"},
    {id: 7, name:"City Ledger to Reddorz"},
    {id: 8, name:"Staff Ledger"},
  ]);

  const [roomNumber, setRoomNumber] = useState("");
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [hotelName, setHotelName] = useState("");
  const [allRoom, setAllRoom] = useState("");
  const [allAvailRoom, setAllAvailRoom] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState({id: "", name:"", number:"", propertyId: ""});
  const [activePage, setActivePage] = useState(1)
  
  const [birthDate, setBirthDate] = useState(null);
  const [checkin, setCheckin] = useState(Date.now());
  const [checkout, setCheckout] = useState(Date.now() + + 24 * 60 * 60 * 1000);
  
  // Current data
  const [hotelNameCurr, setHotelNameCurr] = useState("");
  const [roomTypeCurr, setRoomTypeCurr] = useState("");
  const [roomNumberCurr, setRoomNumberCurr] = useState("");
  const [checkinCurr, setCheckinCurr] = useState(Date.now());
  const [checkoutCurr, setCheckoutCurr] = useState(Date.now() + + 24 * 60 * 60 * 1000);

  const doUpdateReservation = (e) => {
    e.preventDefault()
    let dataUpdate = {
      name,
      id_card: KTP,
      address,
      email,
      country,
      status:1,
      hotel_id: selectedHotel?.id,
      phone,
      price,
      room_id: selectedRoom.id,
      room_number: selectedRoom.number,
      property_id: selectedRoom.propertyId,
      notes,
      birth_date: birthDate ? Math.floor(birthDate) : Math.floor(new Date()),
      guests,
      payment_method: paymentMethod.name,
      booking_source: bookingSource.name,
      start_date: Math.floor(checkin),
      end_date: Math.floor(checkout),
    }
    // console.log("update Reservation", dataUpdate)
    setUpdateDetailReservation(dispatch, dataUpdate, reservationId)
    resetAllAvailRoom(dispatch)
  }

  const handleRoomType = (allRoom, type) => {
    let result = []
    for( let i=0 ; i<allRoom.length ; i++ ){
      let oneRoom = {}
      oneRoom['id'] = allRoom[i].id
      oneRoom['name'] = allRoom[i].type
      if( type === "availability" ){
        oneRoom['property Id'] = allRoom[i].property.id
        oneRoom['status'] = allRoom[i].status
        oneRoom['available'] = allRoom[i].available
        oneRoom['Tipe Kamar'] = allRoom[i].property.type
        oneRoom['Kapasitas Kamar'] = allRoom[i].property.capacity
        oneRoom['Nomor Kamar'] = allRoom[i].room_number
      }
      if(oneRoom.id == roomType){
        oneRoom['selected'] = true;
      }else{
        oneRoom['selected'] = false;
      }
      result.push(oneRoom)
    }
    
    if( type === "availability" ){
      setAllAvailRoom(result)
    }else{
      setAllRoom(result)
    }
  }
  
  const handleSelect = (e, type) => {
    const splitValue = e.target.value.split("||")
    if(type === "Nama Hotel"){
      localStorage.setItem('hotelIdReserv',splitValue[0])
      setSelectedHotel({id: splitValue[0], type: splitValue[1]}) 
    }else if(type === "Metode Pembayaran") {
      setPaymentMethod({id: splitValue[0], name: splitValue[1]}) 
    }else{
      setBookingSource({id: splitValue[0], name: splitValue[1]}) 
    }
  }

  const checkAvailRoom = (e, page=0) => {
    if(e){
      e.preventDefault()
    }
    let param={
      start_date: Math.floor(checkin),
      end_date: Math.floor(checkout),
    }
    if( localStorage.getItem('role') === 'SUPER_ADMIN' ){
      param['hotel_id'] = selectedHotel.id
    }else if( localStorage.getItem('role') === 'ADMIN' ){
      param['hotel_id'] = localStorage.getItem('hotelId')
    }else{
      param['hotel_id'] = selectedHotel.id
      param['affiliator'] = 'true'
    }
    setAllAvailRoomByDate(dispatch, param, page, reservationId)
    setBookTime(new Date(checkin).toLocaleDateString('en-GB') + " -> " + new Date(checkout).toLocaleDateString('en-GB'))
  }

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber)
    checkAvailRoom(null, pageNumber-1)
  }

  const selectAvailRoom = (e, roomId, roomName, roomNumber, propertyId) => {
    e.preventDefault()
    
    setSelectedRoom({id: roomId, name:roomName, number: roomNumber, propertyId: propertyId})
    checkResponseMessage(true, "Success", "Sukses memilih kamar")
  }

  const doSelectRoom = (e, selectedRoomIdx) => {
    let result = [...allRoom]
    for (let i = 0; i < result.length; i++) {
      if( i == selectedRoomIdx ){
        result[i]['selected'] = !result[i]['selected'];
      }else{
        result[i]['selected'] = false;
      }
    }
    setAllRoom(result)
  }

  useEffect(()=>{
    if( dataReservation.reservationDetailResp ){
      let data = dataReservation.reservationDetailResp
      
      setId(data.id)
      setName(data.guest_name)
      setEmail(data.email)
      setPhone(data.phone)
      setIsLoading(false)
      setGuests(data.guests)
      setNotes(data.notes)
      setPrice(data.price)
      setCountry(data.country)
      setKTP(data.id_card)
      setAddress(data.address)
      setBirthDate(Math.floor(new Date(data.birth_date).getTime()))
      setBookingSource({id: 0, name:data.booking_source})
      setPaymentMethod({id: 0, name: data.payment_method})
      
      setRoomTypeCurr(data.property?.type || "")
      setHotelNameCurr(data.hotel_name)
      setRoomNumberCurr(data.room_number)
      setCheckinCurr(dateFormat(new Date(data.start_date), "dd mmm yyyy"))
      setCheckoutCurr(dateFormat(new Date(data.end_date), "dd mmm yyyy"))
    }
  },[dataReservation.reservationDetailResp])
  
  useEffect(()=>{
    setReservationById(dispatch, reservationId)
    setId(reservationId)
  },[dispatch, reservationId])

  useEffect(()=>{
    if( dataReservation.createReservationResp ){
      let data = dataReservation.createReservationResp
      setName(data.name)
      setEmail(data.email)
      setIsLoading(false)
    }
  },[dataReservation.createReservationResp])

  useEffect(()=>{
    if( dataProperty.propertyResp ){
      handleRoomType(dataProperty.propertyResp)
    }
  },[dataProperty.propertyResp])

  useEffect(()=>{
    if( dataProperty.allAvailRoomResp ){
      handleRoomType(dataProperty.allAvailRoomResp.data, "availability")
      setPagination({
        offset: dataProperty.allAvailRoomResp.offset, 
        limit: 10, 
        total: dataProperty.allAvailRoomResp.total, 
      })
    }
  },[dataProperty.allAvailRoomResp])

  useEffect(()=>{
    if( dataHotel.hotelResp?.length > 0 ){
      setAllAvailHotel(dataHotel.hotelResp)
      setSelectedHotel(dataHotel.hotelResp[0])
      setIsLoading(false)
      setAllProperty(dispatch,"", dataHotel.hotelResp[0].id)
    }else{
      setIsLoading(false)
    }
  },[dataHotel.hotelResp])

  useEffect(()=>{
    if( (localStorage.getItem('role') === 'SUPER_ADMIN' || localStorage.getItem('role') === 'AFFILIATOR' ) && selectedHotel) {
      setAllProperty(dispatch,"", selectedHotel.id)
    }
  },[selectedHotel])

  useEffect(()=>{
    if( localStorage.getItem('role') === 'SUPER_ADMIN' || localStorage.getItem('role') === 'AFFILIATOR' ) {
      setAllHotel(dispatch, 9999)
    }else{
      setAllProperty(dispatch,"", localStorage.getItem('hotelId'))
    }
  },[dispatch])

  

  // setRoomType(data.property?.type || "")
  // setHotelName(data.hotel_name)
  // setRoomNumber(data.room_number)
  // setCheckin(Math.floor(new Date(data.start_date).getTime()))
  // setCheckout(Math.floor(new Date(data.end_date).getTime()))

  const dataFormSuperAdmin = [
    {
      label: "Data Sekarang",
      type: "Title",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Nama Hotel",
      type: "text",
      placeholder: "Masukkan Nama Hotel",
      spaceMd: "3",
      spaceXs: "3",
      notEditable: true,
      value: hotelNameCurr,
    },{
      label: "Tipe Kamar",
      type: "text",
      placeholder: "Masukkan Tipe Kamar",
      spaceMd: "3",
      spaceXs: "3",
      notEditable: true,
      value: roomTypeCurr,
    },{
      label: "Nomor Kamar",
      type: "text",
      placeholder: "Masukkan Nomor Kamar",
      spaceMd: "2",
      spaceXs: "2",
      notEditable: true,
      value: roomNumberCurr,
    },{
      label: "Check in",
      type: "text",
      placeholder: "Masukkan Check in",
      spaceMd: "2",
      spaceXs: "2",
      notEditable: true,
      value: checkinCurr,
    },{
      label: "Check in",
      type: "text",
      placeholder: "Masukkan Check in",
      spaceMd: "2",
      spaceXs: "2",
      notEditable: true,
      value: checkoutCurr,
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Nama Hotel",
      type: "dropdown",
      section: "Nama Hotel",
      placeholder: "Masukkan Nama Hotel",
      spaceMd: "6",
      spaceXs: "12",
      action: handleSelect,
      required: true,
      value: selectedHotel,
      dataDropdown: allAvailHotel,
    },{
      type: "reservation",
      label:"Pilih Tanggal Booking",
      spaceMd: "12",
      spaceXs: "12",
      required: true,
      rooms: allRoom,
      onClickRoom: doSelectRoom,
      onClickCheckAvail: checkAvailRoom, 
      valueCheckin: checkin,
      actionCheckin: setCheckin,
      valueCheckout: checkout,
      actionCheckout: setCheckout,
    },{
      label: "All Available room",
      type: "tableRoomReservation",
      spaceMd: "12",
      spaceXs: "12",
      required: true,
      pagination: pagination,
      setPagination: setPagination,
      value: allAvailRoom,
      action: selectAvailRoom,
      activePage: activePage,
      handlePageChange: handlePageChange,
    },{
      label: "Book Time",
      type: "text",
      placeholder: "Pilih dari kamar tersedia diatas",
      spaceMd: "12",
      spaceXs: "12",
      required: true,
      notEditable: true,
      value: bookTime,
      action: setBookTime,
    },{
      label: "Tipe Kamar*",
      type: "text",
      placeholder: "Pilih dari kamar tersedia diatas",
      spaceMd: "6",
      spaceXs: "12",
      notEditable: true,
      required: true,
      value: selectedRoom.name,
    },{
      label: "Nomor Kamar*",
      type: "text",
      placeholder: "Pilih dari kamar tersedia diatas",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      notEditable: true,
      value: selectedRoom.number,
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Nama*",
      type: "text",
      placeholder: "Masukkan Nama",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: name,
      action: setName,
    },{
      label: "No KTP",
      type: "text",
      placeholder: "Masukkan No KTP",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: KTP,
      action: setKTP,
    },{
      label: "Email",
      type: "text",
      placeholder: "Masukkan Email",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: email,
      action: setEmail,
    },{
      label: "Nomor HP",
      type: "text",
      placeholder: "Masukkan Nomor HP",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: phone,
      action: setPhone,
    },{
      label: "Tanggal Lahir",
      type: "dateAdd",
      placeholder: "Masukkan Tanggal Lahir",
      spaceMd: "6",
      spaceXs: "12",
      availability:true,
      value: birthDate,
      action: setBirthDate,
      required: true,
    },{
      label: "Negara",
      type: "text",
      placeholder: "Masukkan Negara",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: country,
      action: setCountry,
    },{
      label: "Alamat",
      type: "text",
      placeholder: "Masukkan Alamat",
      spaceMd: "12",
      spaceXs: "12",
      required: true,
      value: address,
      action: setAddress,
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Booking Source*",
      section: "Booking Source",
      type: "dropdown",
      placeholder: "Masukkan Booking Source",
      spaceMd: "6",
      spaceXs: "12",
      action: handleSelect,
      required: true,
      value: bookingSource,
      dataDropdown: allAvailBookingSource,
    },{
      label: "Total Pengunjung*",
      type: "text",
      placeholder: "Masukkan Total Pengunjung",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: guests,
      action: setGuests,
    },{
      label: "Metode Pembayaran*",
      section: "Metode Pembayaran",
      type: "dropdown",
      placeholder: "Masukkan Metode Pembayaran",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      action: handleSelect,
      required: true,
      value: paymentMethod,
      dataDropdown: allAvailPaymentMethod,
    },{
      label: "Catatan",
      type: "text",
      placeholder: "Masukkan Catatan",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: notes,
      action: setNotes,
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Total Harga* (Rupiah)",
      type: "text",
      placeholder: "Masukkan Total Harga",
      spaceMd: "12",
      spaceXs: "12",
      required: true,
      value: price,
      currency: true,
      action: setPrice,
    },{
      type: "SPACE",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Update",
      type: "button_submit",
      spaceMd: "3",
      spaceXs: "3",
      action: doUpdateReservation,
    },{
      label: "Kembali",
      link: `../reservationDetail/${reservationId}`,
      type: "buttonWhite",
      spaceMd: "3",
      spaceXs: "3",
    }
  ]

  const dataFormAdmin = [
    {
      label: "Data Sekarang",
      type: "Title",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Nama Hotel",
      type: "text",
      placeholder: "Masukkan Nama Hotel",
      spaceMd: "3",
      spaceXs: "3",
      notEditable: true,
      value: hotelNameCurr,
    },{
      label: "Tipe Kamar",
      type: "text",
      placeholder: "Masukkan Tipe Kamar",
      spaceMd: "3",
      spaceXs: "3",
      notEditable: true,
      value: roomTypeCurr,
    },{
      label: "Nomor Kamar",
      type: "text",
      placeholder: "Masukkan Nomor Kamar",
      spaceMd: "2",
      spaceXs: "2",
      notEditable: true,
      value: roomNumberCurr,
    },{
      label: "Check in",
      type: "text",
      placeholder: "Masukkan Check in",
      spaceMd: "2",
      spaceXs: "2",
      notEditable: true,
      value: checkinCurr,
    },{
      label: "Check in",
      type: "text",
      placeholder: "Masukkan Check in",
      spaceMd: "2",
      spaceXs: "2",
      notEditable: true,
      value: checkoutCurr,
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      type: "reservation",
      label:"Pilih Tanggal Booking",
      spaceMd: "12",
      spaceXs: "12",
      required: true,
      rooms: allRoom,
      onClickRoom: doSelectRoom,
      onClickCheckAvail: checkAvailRoom, 
      valueCheckin: checkin,
      actionCheckin: setCheckin,
      valueCheckout: checkout,
      actionCheckout: setCheckout,
    },{
      label: "All Available room",
      type: "tableRoomReservation",
      spaceMd: "12",
      spaceXs: "12",
      required: true,
      pagination: pagination,
      setPagination: setPagination,
      value: allAvailRoom,
      action: selectAvailRoom,
      activePage: activePage,
      handlePageChange: handlePageChange,
    },{
      label: "Book Time",
      type: "text",
      placeholder: "Pilih dari kamar tersedia diatas",
      spaceMd: "12",
      spaceXs: "12",
      required: true,
      notEditable: true,
      value: bookTime,
      action: setBookTime,
    },{
      label: "Tipe Kamar*",
      type: "text",
      placeholder: "Pilih dari kamar tersedia diatas",
      spaceMd: "6",
      spaceXs: "12",
      notEditable: true,
      required: true,
      value: selectedRoom.name,
    },{
      label: "Nomor Kamar*",
      type: "text",
      placeholder: "Pilih dari kamar tersedia diatas",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      notEditable: true,
      value: selectedRoom.number,
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Nama*",
      type: "text",
      placeholder: "Masukkan Nama",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: name,
      action: setName,
    },{
      label: "No KTP",
      type: "text",
      placeholder: "Masukkan No KTP",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: KTP,
      action: setKTP,
    },{
      label: "Email",
      type: "text",
      placeholder: "Masukkan Email",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: email,
      action: setEmail,
    },{
      label: "Nomor HP",
      type: "text",
      placeholder: "Masukkan Nomor HP",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: phone,
      action: setPhone,
    },{
      label: "Tanggal Lahir",
      type: "dateAdd",
      placeholder: "Masukkan Tanggal Lahir",
      spaceMd: "6",
      spaceXs: "12",
      availability:true,
      value: birthDate,
      action: setBirthDate,
      required: true,
    },{
      label: "Negara",
      type: "text",
      placeholder: "Masukkan Negara",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: country,
      action: setCountry,
    },{
      label: "Alamat",
      type: "text",
      placeholder: "Masukkan Alamat",
      spaceMd: "12",
      spaceXs: "12",
      required: true,
      value: address,
      action: setAddress,
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Booking Source*",
      section: "Booking Source",
      type: "dropdown",
      placeholder: "Masukkan Booking Source",
      spaceMd: "6",
      spaceXs: "12",
      action: handleSelect,
      required: true,
      value: bookingSource,
      dataDropdown: allAvailBookingSource,
    },{
      label: "Total Pengunjung*",
      type: "text",
      placeholder: "Masukkan Total Pengunjung",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: guests,
      action: setGuests,
    },{
      label: "Metode Pembayaran*",
      section: "Metode Pembayaran",
      type: "dropdown",
      placeholder: "Masukkan Metode Pembayaran",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      action: handleSelect,
      required: true,
      value: paymentMethod,
      dataDropdown: allAvailPaymentMethod,
    },{
      label: "Catatan",
      type: "text",
      placeholder: "Masukkan Catatan",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: notes,
      action: setNotes,
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Total Harga* (Rupiah)",
      type: "text",
      placeholder: "Masukkan Total Harga",
      spaceMd: "12",
      spaceXs: "12",
      required: true,
      value: price,
      currency: true,
      action: setPrice,
    },{
      type: "SPACE",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Update",
      type: "button_submit",
      spaceMd: "3",
      spaceXs: "3",
      action: doUpdateReservation,
    },{
      label: "Kembali",
      link: `../reservationDetail/${reservationId}`,
      type: "buttonWhite",
      spaceMd: "3",
      spaceXs: "3",
    }
  ]

  return (    
    isLoading === false && 
    <div className="container_right_form">
      <MainForm
        pageName={"Update Detail Reservation #"+ reservationId}
        dataForm={localStorage.getItem('role') === 'SUPER_ADMIN' ? dataFormSuperAdmin : dataFormAdmin}
        linkAccReview={"../accountReview"}
        pageFor={"detail"}
        email={email}
        onSubmit={doUpdateReservation}
        whiteBackground={true}
      />
    </div>
  );
};

const storage = state => {
  return {
    dataReservation: state.reservation,
    dataProperty: state.property,
    dataHotel: state.hotel,
  };
};

export default connect(
  storage
)(ReservationDetailUpdatePage)
