import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button, Form, InputGroup } from 'react-bootstrap';
import 'rsuite/dist/rsuite.min.css';
import { BiSearchAlt } from 'react-icons/bi'
import { Link } from "react-router-dom";
import styles from './BaseTable.module.scss';
import BaseTable from "./BaseTable";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setAllProperty, setAllHotelProperty } from '../../store/actions/propertyAction'

const PropertyTable = ({
  pageName,
  dispatch, 
  dataProperty,
}) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [activePage, setActivePage] = useState(1)
  const [data, setData] = useState([])
  const [hotelName, setHotelName] = useState([])
  
  const [totalNetIncome, setTotalNetIncome] = useState("")
  const [totalGrossIncome, setTotalGrossIncome] = useState("")
  const [pagination, setPagination] = useState({})
  const [searchKeyword, setSearchKeyword] = useState(null)

  const doSearch = (e) => {
    e.preventDefault()
    let params = {}
    if( searchKeyword ){
      params['search'] = searchKeyword
    }
    if( startDate && endDate ){
      params['start'] = Math.floor(new Date(startDate).getTime() / 1000)
      params['end'] = Math.floor(new Date(endDate).getTime() / 1000)
    }
    setAllProperty(dispatch, 0, params)
  }

  const doClearFilter = (e) => {
    e.preventDefault()
    let params = {search: ""}
    
    setSearchKeyword("")
    setDateRange([null, null])
    setAllProperty(dispatch, 0, params)
  }

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber)
    
    if( localStorage.getItem("hotelId") ){
      setAllHotelProperty(dispatch, pageNumber-1)
    }else{
      setAllProperty(dispatch, pageNumber-1)
    }
  }

  const setDataShown = (datas) => {
    let listData = []
    for (let idx in datas) {
      listData.push({
        'ID': datas[idx].id,
        'Nama Hotel': datas[idx].hotel_name,
        'Tipe Kamar': datas[idx].type,
        'Kapasitas': datas[idx].capacity,
        'Total Kamar': datas[idx].rooms,
      })
    }
    setData(listData)
  }

	useEffect(()=>{
    if( localStorage.getItem("hotelId") ){
      setAllHotelProperty(dispatch, 0)
    }else{
      setAllProperty(dispatch, 0)
    }
	},[dispatch])

  useEffect(()=>{
    if( dataProperty.propertyResp ){
      setDataShown(dataProperty.propertyResp.data)
      setPagination({
        offset: dataProperty.propertyResp.total_pages, 
        limit: 10, 
        total: dataProperty.propertyResp.total, 
      })
    }
  },[dataProperty.propertyResp])

	return (
    <>
      <p className={styles.main_title}>
        {pageName}
      </p>
      <Container className={styles.container}>
        <Row>
          {/* <Col xs="3">
            <Link to={linkAddNew}>
              <Button className={styles.save_button_2}>
                {"New "+pageName}
              </Button>
            </Link>
          </Col> */}
        </Row>
        <Row>
          
        {/* { localStorage.getItem('is_super_admin') === 'true' &&  */}
        <Col xs={{ span:3, offset:9 }} className="mt-4">
          <Link to={"./addProperty"}>
            <Button className={styles.add_button}>
              {"Tambah Property Hotel"}
            </Button>
          </Link>
        </Col>
        {/* } */}
          {/* comment for future usage */}
          {/* <Col xs="5">
            <Form.Label htmlFor="basic-url">Search</Form.Label>
            <InputGroup>
              <InputGroup.Text id="basic-addon2" className={styles.icon_search}>
                {<BiSearchAlt/>}
              </InputGroup.Text>
              <Form.Control 
                className={styles.field_search}
                type={"text"} 
                placeholder={"no resi-tanpa huruf / no property .."}
                onChange={(e)=>setSearchKeyword(e.target.value)}
                value={searchKeyword}
              />
            </InputGroup >
          </Col>
          <Col xs="3">
            <Form.Label htmlFor="basic-url">Range Date</Form.Label>
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              placeholderText="Choose Range Date"
              className={styles.date_picker}
              onChange={(update) => {
                setDateRange(update);
              }}
              isClearable={true}
            />
          </Col> */}
          {/* <Col xs="4" className="mt-4">
            <Button className={styles.save_button} onClick={(e)=>doSearch(e)}>
              {"Cari"}
            </Button>
            &nbsp;
            &nbsp;
            &nbsp;
            <Button className={styles.cancel_button} onClick={(e)=>doClearFilter(e)} >
              {"Batal"}
            </Button>
          </Col> */}
        </Row>
        {data.length > 0 ?
          <BaseTable 
            data={data} 
            pagination={pagination}
            section={"propertyReport"}
            totalNetIncome={totalNetIncome}
            linkDetail={"../propertyDetail/"} 
            totalGrossIncome={totalGrossIncome}
            activePage={activePage}
            handlePageChange={handlePageChange}
          />
          :
          <>
            <br/>
            <br/>
            <p>
              Curently no Property data..
            </p>
          </>
        }
      </Container>
    </>
	);
};

const storage = state => {
  return {
    dataProperty: state.property
  };
};

export default connect(
  storage
)(PropertyTable)  