import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button, Form, InputGroup, } from 'react-bootstrap';
import 'rsuite/dist/rsuite.min.css';
import { BiSearchAlt } from 'react-icons/bi'
import { Link } from "react-router-dom";
import styles from './BaseTable.module.scss';
import BaseTable from "./BaseTable";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setAllReservation } from '../../store/actions/reservationAction'
import { setAllHotel } from '../../store/actions/hotelAction'
import { toRupiah } from 'to-rupiah';


const ReservationTable = ({
  pageName,
  dispatch, 
  dataReservation,
  dataHotel,
}) => {

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [status, setStatus] = useState(null);
  const [name, setName] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [filterHotel, setFilterHotel] = useState([])
  const [roomType, setRoomType] = useState([])
  const [totalAmount, setTotalAmount] = useState([])
  const [reserveTime, setReserveTime] = useState([])
  const [allAvailHotel, setAllAvailHotel] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({})
  const [searchKeyword, setSearchKeyword] = useState("")
  const [search, setSearch] = useState({})

  const doSearch = (e) => {
    e.preventDefault()
    let params = {}
    if( searchKeyword ){
      params['name'] = searchKeyword
    }
    if( status ){
      params['status'] = status
    }
    
    if( localStorage.getItem("role") === 'ADMIN' ){
      params['hotel_id'] = localStorage.getItem("hotelId")
    }else if( localStorage.getItem("role") === 'SUPER_ADMIN' ){
      if( filterHotel ){
        params['hotel_id'] = filterHotel
      }
    }else if( localStorage.getItem("role") === 'AFFILIATOR' ){
      params['affiliator_code'] = localStorage.getItem("affiliator_code")
    }
    
    if( startDate && endDate ){
      params['start'] = Math.floor(new Date(startDate).getTime())
      params['end'] = Math.floor(new Date(endDate).getTime())
    }
    setActivePage(1)
    setSearch(params)
    setAllReservation(dispatch, params, 0)
  }
  
  const doClearFilter = (e) => {
    let params = {search: ""}
    
    setSearchKeyword("")
    setSearch({})
    setActivePage(1)
    setDateRange([null, null])
    
    if( localStorage.getItem("role") === 'ADMIN' ){
      params['hotel_id'] = localStorage.getItem("hotelId")
    }else if( localStorage.getItem("role") === 'AFFILIATOR' ){
      params['affiliator_code'] = localStorage.getItem("affiliator_code")
    }
    setAllReservation(dispatch, params, 0)
  }

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber)
    
    if( localStorage.getItem("role") === 'SUPER_ADMIN' ){
      setAllReservation(dispatch, search, (pageNumber-1))
    }else if( localStorage.getItem("role") === 'ADMIN' ){
      let searchTemp = search
      searchTemp['hotel_id'] = localStorage.getItem('hotelId')
      setSearch(searchTemp)
      setAllReservation(dispatch,  searchTemp, (pageNumber-1))
    }else{
      let searchTemp = search
      searchTemp['affiliator_code'] = localStorage.getItem('affiliator_code')
      setSearch(searchTemp)
      setAllReservation(dispatch,  searchTemp, (pageNumber-1))
    }
  }

  const setDataShown = (datas) => {
    let listData = []
    for (let idx in datas) {
      listData.push({
        'ID': datas[idx].id,
        'Nama Tamu': datas[idx].guest_name,
        'Nama Hotel': datas[idx].hotel_name,
        'Tipe Kamar': datas[idx].property?.type || "-",
        'Booking Source': datas[idx].booking_source,
        'Check In Date': datas[idx].start_date,
        'Check Out Date': datas[idx].end_date,
        'Total Amount': toRupiah(datas[idx].price, { dot: '.', floatingPoint: 0 }),
        'Status Booking': datas[idx].status,
      })
    }
    setData(listData)
  }

	useEffect(()=>{
    if( localStorage.getItem("role") === 'SUPER_ADMIN' ){
      setAllReservation(dispatch, "", 0)
      setAllHotel(dispatch, 9999)
    }else if( localStorage.getItem("role") === 'ADMIN' ){
      setAllReservation(dispatch,  {'hotel_id': localStorage.getItem('hotelId')}, 0)
    }else if( localStorage.getItem("role") === 'AFFILIATOR' ){
      setAllReservation(dispatch,  {'affiliator_code': localStorage.getItem('affiliator_code')}, 0)
    }
	},[dispatch])

  useEffect(()=>{
    if( dataReservation.allReservationResp ){
      setDataShown(dataReservation.allReservationResp.data)
      setPagination({
        offset: dataReservation.allReservationResp.total_pages, 
        limit: 10, 
        total: dataReservation.allReservationResp.total, 
      })
      
      setTimeout(() => { 
        setIsLoading(false)
      }, 1000)
    }
  },[dataReservation.allReservationResp])

  useEffect(()=>{
    if( dataHotel.hotelResp ){
      setAllAvailHotel(dataHotel.hotelResp)
    }
  },[dataHotel.hotelResp])

	return (
    <>
      {!isLoading &&
        <Container className={styles.container}>
          <Row>
            <Col xs={{ span:3 }} className="mt-4">
              <p className={styles.main_title}>
                {pageName}
              </p>
            </Col>
            <Col xs={{ span:3, offset:6 }} className="mt-4">
              <Link to={"../reservation/addReservation"}>
                <Button className={styles.add_button}>
                  {"Tambah Reservation"}
                </Button>
              </Link>
            </Col>
          </Row>
          <Row className="mt-3">
            {/* filter commented for future usage  */}
            <Col xs={{ span:2}}>
              <Form.Label htmlFor="basic-url">Filter by Name</Form.Label>
              <InputGroup>
                <InputGroup.Text id="basic-addon2" className={styles.icon_search}>
                  {<BiSearchAlt/>}
                </InputGroup.Text>
                <Form.Control 
                  className={styles.field_search}
                  type={"text"} 
                  placeholder={"Name . . ."}
                  onChange={(e)=>setSearchKeyword(e.target.value)}
                  value={searchKeyword}
                />
              </InputGroup >
            </Col>
            <Col xs="2">
              <Form.Label htmlFor="basic-url">Filter by Status</Form.Label>
              <Form.Select aria-label="Choose Status" className={styles.field_form} 
                onChange={ (e)=> setStatus(e.target.value)}
                // setStatus(e.target.value)
                // 1 - arrival
                // 2 - check in
                // 3 - check out
                // 4 - no show
                // 5 - cancelled
              >
                <option value="">{"Semua Status"}</option>
                <option value="1">{"Arrival"}</option>
                <option value="2">{"Check In"}</option>
                <option value="3">{"Check Out"}</option>
                <option value="4">{"No Show"}</option>
                <option value="5">{"Cancelled"}</option>
              </Form.Select>
            </Col>
            <Col xs="3">
              <Form.Label htmlFor="basic-url">Filter by Checkin Date</Form.Label>
              <DatePicker
                selectsRange={true}
                placeholderText="Choose Checkin Date"
                startDate={startDate}
                endDate={endDate}
                className={styles.date_picker}
                onChange={(update) => {
                  setDateRange(update);
                }}
                isClearable={true}
              />
            </Col>
            { localStorage.getItem("role") === 'SUPER_ADMIN' ?
              <>
                <Col xs="2">
                  <>
                    <Form.Label htmlFor="basic-url">Filter by Hotel</Form.Label>
                    <Form.Select aria-label="Choose Status" className={styles.field_form} 
                      onChange={ (e)=> setFilterHotel(e.target.value)}
                      >
                      <option value={""}>{"Semua Hotel"}</option>
                      {allAvailHotel.map( (item, index)=>(
                        <option key={index} value={item.id}>{item.name}</option>
                      ))}
                    </Form.Select>
                  </>
                </Col>
                <Col xs={{span:3}} className="mt-4">
                  <Button className={styles.save_button} onClick={(e)=>doSearch(e)}>
                    {"Apply"}
                  </Button>
                  &nbsp;
                  &nbsp;
                  &nbsp;
                  <Button className={styles.cancel_button} onClick={(e)=>doClearFilter(e)} >
                    {"Reset"}
                  </Button>
                </Col>
              </>
              :
              <Col xs={{span:3, offset:2}} className="mt-4">
                <Button className={styles.save_button} onClick={(e)=>doSearch(e)}>
                  {"Apply"}
                </Button>
                &nbsp;
                &nbsp;
                &nbsp;
                <Button className={styles.cancel_button} onClick={(e)=>doClearFilter(e)} >
                  {"Reset"}
                </Button>
              </Col>
            }
          </Row>
          {data.length > 0 ?
            <BaseTable 
              data={data} 
              linkDetail={"../reservationDetail/"} 
              pagination={pagination}
              section={"reservation"}
              activePage={activePage}
              handlePageChange={handlePageChange}
            />
            :
            <>
              <br/>
              <br/>
              <p>
                Curently no Reservation data..
              </p>
            </>
          }
        </Container>
      }
    </>
	);
};

const storage = state => {
  return {
    dataReservation: state.reservation,
    dataHotel: state.hotel,
  };
};

export default connect(
  storage
)(ReservationTable)  