import React, { useEffect, useState } from "react";
import MainForm from '../components/MainForm/MainForm'
import { connect } from "react-redux";
import { setCreateProperty } from '../store/actions/propertyAction'
import { setAllHotel } from '../store/actions/hotelAction'
import Swal from 'sweetalert2';

const AddPropertyPage = ({ dispatch, dataProperty, dataHotel }) => {
  const [isLoading, setIsLoading] = useState(true);

  const [hotelName, setHotelName] = useState("");
  const [roomType, setRoomType] = useState("");
  const [roomCapacity, setRoomCapacity] = useState("");
  const [selectedHotel, setSelectedHotel] = useState("");
  const [availHotels, setAllAvailHotels] = useState([]);
  const [roomDatas, setRoomDatas] = useState([{room_number:0, active:0}]);
  const [totalRoom, setTotalRoom] = useState("");
  const [roomValue, setRoomValue] = useState({
    room_number: "",
  });
  const [dataAllRoom, setDataAllRoom] = useState([]);

  const addRoomAction = (e) => {
    e.preventDefault()
    const dataRoom = [...dataAllRoom]
    dataRoom.push({room_number: roomValue.room_number})
    roomValueHandle("", "room_number")
    setDataAllRoom(dataRoom)
  }

  const roomValueHandle = (value, section) =>{
    const curr = {...roomValue}
    if(section === "room_number"){
      curr.room_number = value
    }
    setRoomValue(curr)
  }

  const doCreateProperty = (e) => {
    e.preventDefault()
    if(roomDatas.length === 0){
      Swal.fire({
        title: 'Warning',
        text: 'Masukkan setidaknya 1 kamar untuk tipe kamar ini',
        icon: 'warning',
        confirmButtonColor: '#1b4460',
      })
    }else{
      const name = localStorage.getItem('hotelId') || selectedHotel.id
      const dataCreate = {
        hotel_id: name,
        type: roomType,
        capacity: roomCapacity,
        rooms: totalRoom,
        rooms_data: roomDatas
      }
      setCreateProperty(dispatch, dataCreate)
    }

  }
  
  const handleSelect = (e) => {
    const splitValue = e.target.value.split("||")
    setSelectedHotel({id: splitValue[0], name: splitValue[1]}) 
  }

  useEffect(()=>{
    if( dataProperty.createPropertyResp ){
    }
  },[dataProperty.createPropertyResp])

  useEffect(()=>{
    if( dataHotel.hotelResp ){
      // set id : .. , name: ...
      setAllAvailHotels(dataHotel.hotelResp)
      setSelectedHotel(dataHotel.hotelResp[0])
      setTimeout(() => {
        setIsLoading(false)
    }, 1000);
    }
  },[dataHotel.hotelResp])

  useEffect(()=>{
    if( localStorage.getItem('is_super_admin') === 'true' ){
      setAllHotel(dispatch, 9999)
    }else{
      setHotelName(localStorage.getItem('hotelName'))
      setIsLoading(false)
    }
  },[])

  const dataForm = [
    {
      label: "Nama Tipe Kamar",
      type: "text",
      placeholder: "Masukkan nama tipe kamar",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: roomType,
      action: setRoomType,
    },{
      label: "Kapasitas Kamar",
      type: "text",
      placeholder: "Masukkan kapasitas kamar",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: roomCapacity,
      action: setRoomCapacity,
    },{
      label: "Total dan Nomor kamar dimasukkan setelah pembuatan kamar",
      type: "LABEL_INFO",
      spaceMd: "6",
      spaceXs: "12",
    },{
      type: "SPACE",
      spaceMd: "6",
      spaceXs: "6",
    },{
      label: "Simpan",
      type: "button_submit",
      spaceMd: "3",
      spaceXs: "3",
      action: doCreateProperty,
    },{
      label: "Kembali",
      type: "buttonWhite",
      spaceMd: "3",
      link: '../property',
      spaceXs: "3",
    // },{
    //   type: "SEPARATOR",
    //   spaceMd: "12",
    //   spaceXs: "12",
    // },{
    //   label: "List Room",
    //   type: "tableRoom",
    //   placeholder: "List Room",
    //   spaceMd: "12",
    //   spaceXs: "12",
    //   value: dataAllRoom,
    //   action: addRoomAction,
    //   valueAdmin: roomValue,
    //   actionOnChange : roomValueHandle,
    }
  ]

  const dataFormSuperAdmin = [
    {
      label: "Nama Hotel",
      type: "dropdown",
      placeholder: "Masukkan Nama Hotel",
      spaceMd: "6",
      spaceXs: "12",
      value: hotelName,
      action: handleSelect,
      required: true,
      notEditable: localStorage.getItem('is_super_admin') === 'true' ? false : true,
      dataDropdown: availHotels,
    },{
      type: "SPACE",
      spaceMd: "6",
      spaceXs: "6",
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Nama Tipe Kamar",
      type: "text",
      placeholder: "Masukkan nama tipe kamar",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: roomType,
      action: setRoomType,
    },{
      label: "Kapasitas Kamar",
      type: "text",
      placeholder: "Masukkan kapasitas kamar",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: roomCapacity,
      action: setRoomCapacity,
    },{
      label: "*Total dan Nomor kamar dimasukkan setelah pembuatan kamar",
      type: "LABEL_INFO",
      spaceMd: "6",
      spaceXs: "12",
    },{
      type: "SPACE",
      spaceMd: "6",
      spaceXs: "6",
    },{
      label: "Simpan",
      type: "button_submit",
      spaceMd: "3",
      spaceXs: "3",
      action: doCreateProperty,
    },{
      label: "Kembali",
      type: "buttonWhite",
      spaceMd: "3",
      link: '../property',
      spaceXs: "3",
    // },{
    //   type: "SEPARATOR",
    //   spaceMd: "12",
    //   spaceXs: "12",
    // },{
    //   label: "List Room",
    //   type: "tableRoom",
    //   placeholder: "List Room",
    //   spaceMd: "12",
    //   spaceXs: "12",
    //   value: dataAllRoom,
    //   action: addRoomAction,
    //   valueRoom: roomValue,
    //   actionOnChange : roomValueHandle,
    }
  ]

  return (    
    isLoading === false && 
    <div className="container_right_form">
      <MainForm
        pageName={"Tambah Property (tipe kamar)"}
        dataForm={localStorage.getItem('is_super_admin') === 'true' ? dataFormSuperAdmin : dataForm}
        linkAccReview={"../accountReview"}
        pageFor={"detail"}
        onSubmit={doCreateProperty}
        whiteBackground={true}
      />
    </div>
  );
};

const storage = state => {
  return {
    dataProperty: state.property,
    dataHotel: state.hotel,
  };
};

export default connect(
  storage
)(AddPropertyPage)
